import {LoginButtonProps} from '../types';
import {useI18n} from '../../../hooks/use-i18n';
import {ReactComponent as IconTwitter} from '../../../assets/svg/twitter.svg';
import {forwardRef, useImperativeHandle} from 'react';
import {api} from '../../../http/api';
import {getConnectUrl} from '../../../util/get-connect-url';
import {AxiosError} from 'axios';
import {usePresetRole} from '../lib/usePresetRole';
import {useUserCountry} from '../../../hooks/use-user-country';

export interface SignInWithTwitterRef {
  twitterLogin: (twitterToken: string, twitterVerifier: string) => Promise<void>;
}

const SignInWithTwitter = forwardRef<SignInWithTwitterRef, LoginButtonProps>((props, ref) => {
  const {onSuccessLogin} = props;
  const {t, language} = useI18n();
  const userCountry = useUserCountry();
  const presetRole = usePresetRole();

  const onTwitterLogin = async (twitterToken: string, twitterVerifier: string): Promise<void> => {
    try {
      const response = await api.post('api/v1/login', {
        method: 'twitter',
        data: {
          twitterToken,
          twitterVerifier,
          language,
          userCountry,
          userType: presetRole,
        },
      });
      if (!response.data.success) {
        alert(response.data.error);
        return;
      }
      const {clearLocalData} = await import('../../../util/logout');
      // Make sure to clear all previous account's data
      await clearLocalData();
      onSuccessLogin?.(response.data.data);
    } catch (e) {
      if (e instanceof AxiosError) {
        alert(e?.response?.data?.error);
        return;
      }
      alert(e);
    }
  };

  useImperativeHandle(ref, () => ({
    twitterLogin: onTwitterLogin,
  }));

  const onTwitterLoginClick = () => {
    window.location.href = `${getConnectUrl()}/api/v1/auth/twitter`;
  };

  return (
    <button className="btn btn--sm btn--black h-52 w-full gap-16 p-16" onClick={onTwitterLoginClick}>
      <IconTwitter />
      {t('sign-in-with-twitter')}
    </button>
  );
});

export default SignInWithTwitter;
