import {forwardRef, useImperativeHandle, useState} from 'react';
import {LoginServiceResponse} from '../../../shared/login-response';
import {updateUser} from '../../../redux/reducers/user-reducer';
import {LoginTokenStorage} from '../../../storages/login-token-storage';
import {useAppDispatch} from '../../../redux/hooks/use-app-dispatch';
import {useNavigate} from 'react-router-dom';
import ChooseRoleModal from '../../../components/ChooseRoleModal/ChooseRoleModal';
import {useModalState} from '../../../hooks/use-modal-state';

export interface OnSuccessLoginRef {
  onSuccessLogin: (data: LoginServiceResponse) => void;
}

const OnSuccessLogin = forwardRef<OnSuccessLoginRef>((props, ref) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {isOpen: isOpenChooseRoleModal, show: showChooseRoleModal, hide: hideChooseRoleModal} = useModalState();
  const [cookie, setCookie] = useState('');

  const onSuccessLogin = (data: LoginServiceResponse) => {
    if (!data.type) {
      setCookie(data.cookie);
      showChooseRoleModal();
    } else {
      dispatch(
        updateUser({
          userId: data.userId,
          type: data.type,
        }),
      );
      // Save cookie only if user has chosen their role already
      LoginTokenStorage.set(data.cookie);
      navigate('/app/chat/search');
    }
  };

  useImperativeHandle(ref, () => ({
    onSuccessLogin,
  }));

  return (
    <>
      <ChooseRoleModal isOpen={isOpenChooseRoleModal} hide={hideChooseRoleModal} cookie={cookie} />
    </>
  );
});

export default OnSuccessLogin;
