// client to server events
export const START_NEW_SEARCH = 'start-new-search';
export const STOP_SEARCHING = 'stop searching';
export const SEND_NEW_MESSAGE = 'send-new-message';
export const CLOSE_DIALOG = 'close-dialog';
export const DELETE_DIALOG = 'delete-dialog';
export const UPDATE_PROFILE_SETTINGS = 'update-profile-settings';
export const VERIFICATION_REQUEST = 'verification-request';
export const GENERATE_PAYMENT_LINK = 'generate-payment-link';
export const PAY_BY_TRANSACTION_ID = 'pay-by-transaction-id';
export const LOAD_SAVED_PAYMENT_METHODS = 'load-saved-payment-methods';
export const SEND_TIP = 'send-tip';
export const LOGOUT = 'logout';
export const READ_MESSAGES = 'read-messages';
export const USER_TYPING = 'user-typing';
export const SEND_REPORT = 'send-report';
export const BLOCK_PARTNER = 'block-partner';
export const ADD_WITHDRAWAL_METHOD = 'add-withdrawal-method';
export const REQUEST_WITHDRAW_MONEY = 'request-withdraw-money';
export const REMOVE_WITHDRAWAL_METHOD = 'remove-withdrawal-method';
export const FETCH_MODELS = 'fetch-models';
export const START_CHATTING = 'start-chatting';
export const LINK_TELEGRAM = 'link-telegram';
export const UNLINK_TELEGRAM = 'unlink-telegram';
export const CLICK_TOP_UP_LINK = 'click-top-up-link';
export const RESEND_CONFIRMATION_MAIL = 'resend-confirmation-mail';
export const DELETE_PROFILE = 'delete-profile';
export const CANCEL_ACCOUNT_DELETION = 'cancel-account-deletion';
export const UPDATE_LANGUAGE = 'update-language';
export const FETCH_DIALOGS = 'fetch-dialogs';
export const FETCH_DIALOG = 'fetch-dialog';
export const PURCHASE_PAID_MEDIA = 'purchase-paid-media';

// server to client events
export const NEW_DIALOG = 'new-dialog';
export const DIALOG_UPDATED = 'dialog-updated';
export const NEW_MESSAGE = 'new-message';
export const NEW_TRANSACTION = 'new-transaction';
export const PARTNER_INFO_UPDATED = 'partner-info-updated';
export const VERIFICATION_INFO_UPDATED = 'verification-info-updated';
export const USER_UPDATED = 'user-updated';
export const FETCH_INITIAL_DATA = 'fetch-initial-data';
export const LOAD_WITHDRAWAL_METHODS = 'load-withdrawal-methods';
export const UPDATE_AMOUNT_CENTS_ON_HOLD = 'update-amount-cents-on-hold';
export const VALIDATION_PROFILE_ERRORS = 'validation-profile-errors';
export const FORCE_LOGOUT = 'force-logout';
export const MESSAGE_UPDATE = 'message-update';
