import {lazy} from 'react';
import {Route, BrowserRouter as Router, Routes} from 'react-router-dom';
import {NavigationView} from './navigation';
import {RenderWithSuspense} from './components/RenderWithSuspense';
import HomeWrapper from './pages/home-pages/home-wrapper';
import MainPage from './pages/home-pages/main-page';

// Home Lazy
const HomePage404 = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/page-404'));
const FAQPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/faq-page'));
const ContactUs = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/contact-us'));
const SetNewPasswordPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/set-new-password-page'));
const SentPasswordResetRequestPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/home-pages/sent-password-reset-request-page'),
);
const ModelPage = lazy(() => import(/* webpackPrefetch: true */ './pages/home-pages/model-page'));
const EmailNotificationUnsubscribeSuccessPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/home-pages/email-notification-unsubscribe-success-page'),
);

// App Lazy
const InnerFaq = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/inner-faq'));
const VerificationPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/verification-page'),
);
const SafetyTipsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/safety-tips-page'),
);
const AppPage404 = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/page-404'));
const AppWrapper = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/app-wrapper'));
const ChatTabWrapper = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/chat-tab-wrapper'));
const SearchPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/search-page'));
const ChatPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/chat-tab/chat-page'));
const SettingsTabWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/settings-tab-wrapper'),
);
const ProfileSettingsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/profile-settings-page'),
);
const FinanceTabWrapper = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/finance-tab-wrapper'),
);
const FinancePage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/finance-page'),
);
const WithdrawalMethodsPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/withdrawal-methods-page'),
);
const AddWithdrawalMethodPage = lazy(
  () => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/finance-tab/add-withdrawal-method-page'),
);
const LanguagePage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/settings-tab/language-page'));
const SupportPage = lazy(() => import(/* webpackPrefetch: true */ './pages/app-pages/support-page'));

export const router = (
  <Router>
    <Routes>
      <Route path="/" element={<HomeWrapper />}>
        <Route path="/login/:method?/:verifier?/:token?" element={<MainPage showLoginModalOnMount={true} />} />
        <Route index element={<MainPage showLoginModalOnMount={false} />} />
        <Route path="/redirect" element={<MainPage showLoginModalOnMount={false} />} />
        <Route
          path="faq/:userType?"
          element={
            <RenderWithSuspense>
              <FAQPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="reset-password/new/:token"
          element={
            <RenderWithSuspense>
              <SetNewPasswordPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="reset-password/request"
          element={
            <RenderWithSuspense>
              <SentPasswordResetRequestPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="contact-us"
          element={
            <RenderWithSuspense>
              <ContactUs />
            </RenderWithSuspense>
          }
        />
        <Route
          path="model/:modelId"
          element={
            <RenderWithSuspense>
              <ModelPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="email-notification/unsubscribe/success"
          element={
            <RenderWithSuspense>
              <EmailNotificationUnsubscribeSuccessPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="*"
          element={
            <RenderWithSuspense>
              <HomePage404 />
            </RenderWithSuspense>
          }
        />
      </Route>
      <Route
        path="app"
        element={
          <RenderWithSuspense>
            <AppWrapper />
          </RenderWithSuspense>
        }
      >
        <Route
          path="chat"
          element={
            <RenderWithSuspense>
              <ChatTabWrapper />
            </RenderWithSuspense>
          }
        >
          <Route
            path="search"
            element={
              <RenderWithSuspense>
                <SearchPage />
              </RenderWithSuspense>
            }
          />
          <Route
            path=":dialogId?"
            element={
              <RenderWithSuspense>
                <ChatPage />
              </RenderWithSuspense>
            }
          />
        </Route>
        <Route
          path="inner-faq"
          element={
            <RenderWithSuspense>
              <InnerFaq />
            </RenderWithSuspense>
          }
        />
        <Route
          path="support"
          element={
            <RenderWithSuspense>
              <SupportPage />
            </RenderWithSuspense>
          }
        />
        <Route
          path="settings"
          element={
            <RenderWithSuspense>
              <SettingsTabWrapper />
            </RenderWithSuspense>
          }
        >
          <Route
            path="profile"
            element={
              <RenderWithSuspense>
                <ProfileSettingsPage />
              </RenderWithSuspense>
            }
          />
          <Route
            path="finance"
            element={
              <RenderWithSuspense>
                <FinanceTabWrapper />
              </RenderWithSuspense>
            }
          >
            <Route
              index
              element={
                <RenderWithSuspense>
                  <FinancePage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="withdrawal-methods"
              element={
                <RenderWithSuspense>
                  <WithdrawalMethodsPage />
                </RenderWithSuspense>
              }
            />
            <Route
              path="add-withdrawal-method/:method"
              element={
                <RenderWithSuspense>
                  <AddWithdrawalMethodPage />
                </RenderWithSuspense>
              }
            />
          </Route>
          <Route
            path="language"
            element={
              <RenderWithSuspense>
                <LanguagePage />
              </RenderWithSuspense>
            }
          />
          <Route
            path="verification"
            element={
              <RenderWithSuspense>
                <VerificationPage />
              </RenderWithSuspense>
            }
          />
          <Route
            path="safety-tips"
            element={
              <RenderWithSuspense>
                <SafetyTipsPage />
              </RenderWithSuspense>
            }
          />
        </Route>
        <Route
          path="*"
          element={
            <RenderWithSuspense>
              <AppPage404 />
            </RenderWithSuspense>
          }
        />
      </Route>
    </Routes>
    <NavigationView />
  </Router>
);
