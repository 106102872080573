import {createRef, FormEvent, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {LoginModalHandlerRef, LoginModalRef} from '../../types';
import {Modal} from '../../../../ui/Modal';
import {useI18n} from '../../../../hooks/use-i18n';
import {useModalState} from '../../../../hooks/use-modal-state';
import CloseModalButton from '../../../../components/CloseModalButton';
import SignInWithGoogle from '../SignInWithGoogle';
import SignInWithTwitter from '../SignInWithTwitter';
import OrSeparator from '../OrSeparator';
import AdBlockError from '../AdBlockError';
import EmailInput from '../EmailInput';
import SubmitButton from '../SubmitButton';
import PasswordInput from '../PasswordInput';
import {useToggle} from '../../../../hooks/use-toggle';
import {api} from '../../../../http/api';
import {LoginPostResponse} from '../../../../shared/login-response';
import ForgotPassword from './ForgotPassword';
import LoginModalBottomDescription from './LoginModalBottomDescription';
import {sleep} from '../../../../util/helpers/sleep';
import ResetPasswordModal from '../ResetPasswordModal/ResetPasswordModal';
import OnSuccessLogin, {OnSuccessLoginRef} from '../OnSuccessLogin';
import {AxiosError} from 'axios';
import {isSharedError} from '../../../../shared/shared-error';
import {PasswordError} from '../PasswordError';
import {useUserCountry} from '../../../../hooks/use-user-country';

const loginModalHandlerRef = createRef<LoginModalHandlerRef>();

const LoginModalHandler: LoginModalHandlerRef = (() => {
  const show: LoginModalHandlerRef['show'] = (): void => {
    if (loginModalHandlerRef.current?.show) {
      loginModalHandlerRef.current.show();
    }
  };

  const hide: LoginModalHandlerRef['hide'] = (): void => {
    if (loginModalHandlerRef.current?.hide) {
      loginModalHandlerRef.current.hide();
    }
  };

  const twitterLogin: LoginModalHandlerRef['twitterLogin'] = async (twitterToken, twitterVerifier) => {
    // In order to call twitterLogin the modal must be mounted, if it's not open then call and reschedule the task
    while (!loginModalHandlerRef.current?.isOpen) {
      show();
      await sleep(25); // Wait for the modal to possibly open
    }
    if (loginModalHandlerRef.current?.twitterLogin) {
      return loginModalHandlerRef.current?.twitterLogin(twitterToken, twitterVerifier);
    }
  };

  return {
    hide,
    show,
    isOpen: Boolean(loginModalHandlerRef.current?.isOpen),
    twitterLogin,
  };
})();

const LoginModal = () => {
  const {isOpen, show, hide} = useModalState();
  const successLoginRef = useRef<OnSuccessLoginRef>(null);
  const {t, language} = useI18n();
  const userCountry = useUserCountry();
  const signInWithTwitterRef = useRef<LoginModalRef>(null);
  const [showAdBlockError, setShowAdBlockError] = useState(false);
  const [email, setEmail] = useState('');
  const [isEmailIncorrect, setIsEmailIncorrect] = useState(false);
  const [password, setPassword] = useState('');
  const [revealedPassword, toggleRevealedPassword] = useToggle();
  const [passwordError, setPasswordError] = useState('');
  const [isPasswordEmpty, setPasswordEmpty] = useState(false);
  const {
    isOpen: isOpenResetPasswordModal,
    show: showResetPasswordModal,
    hide: hideResetPasswordModal,
  } = useModalState();
  const disabled = isEmailIncorrect || Boolean(passwordError) || isPasswordEmpty;

  useEffect(() => {
    setPasswordEmpty(password.length === 0);
    // Clear password errors on password or email change
    setPasswordError('');
  }, [password, email]);

  useImperativeHandle(loginModalHandlerRef, () => ({
    twitterLogin: signInWithTwitterRef.current?.twitterLogin
      ? signInWithTwitterRef.current?.twitterLogin
      : async () => {},
    show,
    hide,
    isOpen,
  }));

  const onLoginClick = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (disabled) {
      return;
    }

    try {
      const response = await api.post<LoginPostResponse>('api/v1/login', {
        method: 'email',
        data: {
          email,
          password,
          language,
          userCountry,
        },
      });
      if (!response.data.success) {
        alert(response.data.error);
        return;
      }
      successLoginRef?.current?.onSuccessLogin(response.data.data);
    } catch (e) {
      if (!(e instanceof AxiosError)) {
        setPasswordError('email-or-password-wrong');
        return;
      }
      const axiosErr = e?.response?.data?.error;
      if (!axiosErr) {
        setPasswordError('email-or-password-wrong');
        return;
      }

      if (isSharedError(axiosErr)) {
        setPasswordError(axiosErr.translation);
        return;
      }

      setPasswordError('email-or-password-wrong');
    }
  };

  const onForgotPasswordClick = () => {
    hide();
    showResetPasswordModal();
  };

  return (
    <>
      <OnSuccessLogin ref={successLoginRef} />
      <ResetPasswordModal isOpen={isOpenResetPasswordModal} hide={hideResetPasswordModal} />
      <Modal
        isOpen={isOpen}
        hide={hide}
        backdropClassName={'p-0 lg:p-20'}
        className={'max-h-full w-full overscroll-none rounded px-20 py-24 lg:h-auto lg:w-500 lg:p-54'}
      >
        <CloseModalButton onClick={hide} />
        <div className="mb-14 text-center text-[2.6rem] font-bold sm:text-2xl">{t('log-in')}</div>
        <div className={'flex flex-col items-center justify-center gap-14'}>
          <SignInWithGoogle
            setShowAdBlockError={setShowAdBlockError}
            onSuccessLogin={successLoginRef?.current?.onSuccessLogin}
          />
          <SignInWithTwitter ref={signInWithTwitterRef} onSuccessLogin={successLoginRef?.current?.onSuccessLogin} />
        </div>
        <OrSeparator />
        <form onSubmit={onLoginClick} className={'flex flex-col'}>
          <EmailInput
            email={email}
            setEmail={setEmail}
            setEmailIncorrect={setIsEmailIncorrect}
            checkEmailExistence={false}
          />
          <PasswordInput
            label={'password'}
            password={password}
            setPassword={setPassword}
            type={'current-password'}
            // Show password error only if email is correct
            hasError={!isEmailIncorrect && Boolean(passwordError)}
            revealed={revealedPassword}
            toggleRevealed={toggleRevealedPassword}
          />
          <PasswordError errorKey={passwordError} onClick={onForgotPasswordClick} />
          <ForgotPassword onClick={onForgotPasswordClick} />
          <SubmitButton disabled={disabled} titleKey={'log-in'} />
          <LoginModalBottomDescription />
        </form>
        <AdBlockError isVisible={showAdBlockError} />
      </Modal>
    </>
  );
};

export default LoginModal;
export {LoginModalHandler};
