import {useState, useCallback} from 'react';

type RequestFunction = (...args: any[]) => Promise<any>;

export function useFetchingState(requestFn: RequestFunction): [RequestFunction, boolean] {
  const [isFetching, setFetching] = useState(false);

  const wrappedRequest = useCallback(
    async (...args: any[]) => {
      setFetching(true);
      try {
        return await requestFn(...args);
      } catch (e) {
        throw e;
      } finally {
        setFetching(false);
      }
    },
    [requestFn],
  );

  return [wrappedRequest, isFetching];
}
