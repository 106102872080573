import {useI18n} from '../hooks/use-i18n';
import {ReactComponent as IconArrowUp} from '../assets/svg/arrow-up-long.svg';
import {di} from '../dependency-injector';

const ExternalBrowserBanner = () => {
  const {T} = useI18n();

  if (!di.userAgentService.isAnyInAppBrowser()) {
    // Show banner only if user uses in app browser
    return null;
  }

  const symbol = di.userAgentService.isTelegramBrowserAndroid() ? '⋮' : '•••';

  return (
    <div className={'text-white flex flex-row gap-10 justify-end items-center p-15 -mb-10 bg-gradient-primary'}>
      <span>
        <T
          keyName={'external-browser-hint-custom-symbol'}
          params={{b: (text) => <b>{text}</b>, symbol: () => <>{symbol}</>}}
        />
      </span>
      <IconArrowUp className={'stroke-white'} />
    </div>
  );
};

export default ExternalBrowserBanner;
